import './bootstrap';

window.generateColors = function (baseColor, count) {
    let colors = [];
    for (let i = 0; i < count; i++) {
        const shade = Math.floor(255 - 200 * (i / count)); // Ajuste la luminosité
        colors.push(`rgba(${baseColor.r}, ${baseColor.g}, ${baseColor.b}, ${shade / 255})`);
    }
    return colors;
}

window.baseColor = {r: 50, g: 168, b: 207}; // RGB pour #32A8CF
